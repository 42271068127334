<template>
  <div class="px-1 w-100">
    <b-row>
      <h2>PERSONAL INFORMATION</h2>
    </b-row>
    <b-row v-if="packs" class="my-1">
      <h3 v-for="(pack, i) in packs" :key="i" class="mx-1">
        <b-badge
          :variant="pack.status ? 'light-info' : 'light-secondary'"
          class="px-1"
          :class="isResponsibleAccount && !pack.status ? '' : 'cursor-pointer'"
          pill
          @click="isResponsibleAccount && !pack.status ? '' : setPack(pack)"
        >
          {{ pack.name_benefit }}
        </b-badge>
      </h3>
    </b-row>
    <!-- <b-row>
      <b-col>
        <b-form-group label="Client">
          <b-form-input v-model="client.client" disabled> </b-form-input>
        </b-form-group>
      </b-col>
    </b-row> -->
    <b-row>
      <b-col lg="auto">
        <b-skeleton-img v-if="!validateImg" width="80px" height="80px" />
        <b-img
          v-if="validateImg"
          :src="getProgramLogo(client.program_name)"
          thumbnail
          fluid
          style="max-width: 100px; max-height: 100px"
        />
      </b-col>
      <b-col lg="auto">
        <b-skeleton-wrapper :loading="!validateAccount">
          <template #loading>
            <b-card no-body>
              <b-skeleton width="85%" style="min-width: 124px" />
              <b-skeleton width="55%" style="min-width: 95px" />
              <b-skeleton width="70%" style="min-width: 110px" />
            </b-card>
          </template>
        </b-skeleton-wrapper>
        <b-form-group style="margin-bottom: 5px !important">
          <template v-if="validateAccount" :class="statusDark">
            <span
              v-if="!isResponsibleAccount"
              style="font-weight: bolder; font-size: 17.5px"
              class="cursor-pointer"
              @click="openEditLead"
              >{{ client.client }}
            </span>
            <span v-else style="font-weight: bolder; font-size: 17.5px"
              >{{ client.client }}
            </span>
            <span
              v-if="
                $store.getters['NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO']
                  .is_responsible
              "
              class="text-primary"
            >
              <tabler-icon
                :icon="
                  $store.getters[
                    'NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO'
                  ].is_responsible === currentUser.user_id
                    ? 'StarIcon'
                    : 'LockIcon'
                "
                size="15"
                style="margin-left: 5px"
              />
              <strong>CEO's client</strong>
            </span>
            <br />
          </template>
        </b-form-group>
        <b-form-group style="margin-bottom: 5px !important">
          <span style="font-size: 14.5px"
            ><span
              style="font-weight: bolder; font-size: 14px; margin-right: 5px"
              >Seller:</span
            >
            {{ client.seller }}</span
          >
        </b-form-group>
        <b-form-group style="margin-bottom: 5px !important">
          <template v-if="validateAccount" :class="statusDark">
            <span style="font-size: 14.5px"
              ><span
                style="font-weight: bolder; font-size: 14px; margin-right: 5px"
                >Account:</span
              >
              {{ client.account }}</span
            >
            <br />

            <StatusClientAccount />
          </template>
        </b-form-group>
      </b-col>
    </b-row>

    <br />
    <b-row>
      <b-col cols="8">
        <b-row class="justify-content-around">
          <b-col cols="4">
            <b-form-group label="Sevice">
              <b-form-input v-model="service" disabled />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Address">
              <b-input-group>
                <b-form-input v-model="address" disabled />

                <b-input-group-append>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    class="cursor-pointer"
                    size="30"
                    @click="openModalTrackingChangeAddress"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label="Doc">
              <b-form-input v-model="doc" disabled />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label="Phone">
              <b-form-input v-model="client.phone" disabled />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="justify-content-around">
          <b-col cols="4">
            <b-form-group label="Email">
              <b-form-input v-model="client.email" disabled />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Monthly Pay">
              <b-input-group prepend="$" disabled>
                <b-form-input
                  v-model="client.monthlyAmount"
                  class="border-1"
                  disabled
                />
                <b-input-group-append>
                  <b-button
                    v-b-popover.hover.bottom="msgPopOver"
                    variant="outline-secondary"
                    class="px-1 py-0"
                  >
                    <feather-icon
                      :class="methodPayment"
                      icon="CreditCardIcon"
                      class="cursor-pointer"
                      size="20"
                    />
                    <span v-if="this.typePayment === 'PA'">
                      ({{ client.paymentDay }})
                    </span>
                  </b-button>
                  <b-button
                    variant="outline-secondary"
                    class="px-1 py-0"
                    :disabled="isResponsibleAccount"
                    @click="openModalPaymentsData"
                  >
                    <feather-icon
                      icon="Edit2Icon"
                      class="cursor-pointer"
                      size="20"
                    />
                  </b-button>
                  <b-button
                    variant="outline-secondary"
                    class="px-1 py-0"
                    @click="openModalHisitoryMonthlyPayments"
                  >
                    <feather-icon
                      icon="MoreVerticalIcon"
                      class="cursor-pointer"
                      size="20"
                    />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label="Company Type" class="pt-1">
              <b-form-radio-group
                v-model="client.company"
                :options="[
                  { text: 'CORP', value: 1 },
                  { text: 'LLC', value: 2 },
                ]"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label="DOB">
              <b-form-input v-model="client.dob" disabled />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="4">
        <b-form-group label="Memo">
          <div @click="changeStatusMemo(true)">
            <b-form-textarea
              v-model="msgMemo"
              :class="{ 'cursor-pointer': !isMemoActive }"
              :disabled="!isMemoActive"
              rows="2"
            />
          </div>
        </b-form-group>

        <b-row v-if="isMemoActive">
          <b-col
            ><b-button
              variant="danger"
              class="w-100"
              @click="changeStatusMemo(false)"
              ><feather-icon icon="XIcon" size="20" /></b-button
          ></b-col>
          <b-col
            ><b-button variant="success" class="w-100" @click="editMemo"
              ><feather-icon icon="CheckIcon" size="20" /></b-button
          ></b-col>
        </b-row>
        <p v-else class="text-right">
          Updated by: {{ memo.user }} | {{ memo.date | myGlobalWithHour }}
        </p>
      </b-col>
    </b-row>
    <modal-history-status
      v-if="activeModalHistory"
      @closeMe="closeModalHistory"
    />
    <modal-benefit
      v-if="activeModalBenefit"
      :active="activeModalBenefit"
      @close="closeModalBenefit"
    />
    <modal-tracking-change-address
      v-if="activeTrackingChangeAddress"
      :active="activeTrackingChangeAddress"
      @close="closeModalTrackingChangeAddress"
    />
    <modal-history-payments
      v-if="activeModalHisitoryMonthlyPayments"
      :active="activeModalHisitoryMonthlyPayments"
      @close="closeModalHisitoryMonthlyPayments"
    />
    <modal-monthly-edit
      v-if="activeModalPaymentsData"
      @close="closeModalPaymentsData"
    />
    <lead-update
      v-if="isAddUpdateUserSidebarActive"
      :is-add-new-user-sidebar-active.sync="isAddUpdateUserSidebarActive"
      :lead="S_LEAD_EDIT"
      :type-edit="typeEdit"
      @refresh="refresh"
    />
    <!-- <modal-payments v-if="activeModalPaymentsData" :active="activeModalPaymentsData" @close="closeModalPaymentsData" /> -->
  </div>
</template>
<script>
// vuex
import { mapGetters, mapActions, mapMutations, mapState } from "vuex";
// components
import IconStatusAccount from "@/views/paragon/views/clients/dashboard/views/components/utils/IconStatusAccount.vue";
import VMoney from "v-money";
// modals
import ModalHistoryStatus from "@/views/paragon/views/clients/dashboard/views/components/modals/ModalHistoryStatus.vue";
import ModalBenefit from "@/views/paragon/views/clients/dashboard/views/components/modals/ModalBenefit.vue";
import ModalTrackingChangeAddress from "@/views/paragon/views/clients/dashboard/views/components/modals/ModalTrackingChangeAddress.vue";
import ModalHistoryPayments from "@/views/paragon/views/clients/dashboard/views/components/modals/ModalHistoryPayments.vue";
import ModalMonthlyEdit from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalMonthlyEdit.vue";
// import ModalPayments from '../modals/ModalPayments.vue'
import LeadUpdate from "@/views/crm/views/Lead/lead-module/save/LeadUpdate.vue";
// service
import ClientDashboardService from "@/views/paragon/views/clients/dashboard/services/dashboard.service";

import StatusClientAccount from "@/views/administration/views/clients/components/StatusClientAccount.vue";
export default {
  components: {
    // ModalPayments,
    ModalMonthlyEdit,
    ModalHistoryPayments,
    ModalTrackingChangeAddress,
    ModalBenefit,
    ModalHistoryStatus,
    IconStatusAccount,
    VMoney,
    LeadUpdate,
    StatusClientAccount,
  },
  directives: { money: VMoney },
  data() {
    return {
      service: "",
      doc: "",
      msgMemo: "",
      msgPopOver: "",
      isMemoActive: false,
      activeModalHistory: false,
      activeModalBenefit: false,
      activeTrackingChangeAddress: false,
      activeModalHisitoryMonthlyPayments: false,
      activeModalPaymentsData: false,
      isAddUpdateUserSidebarActive: false,
      vMoney: {
        decimal: ",",
        thousand: ".",
        prefix: "$ ",
        suffix: "",
        precision: 2,
        min: 0.0,
        masked: false,
      },
      validateImg: false,
      validateAccount: false,
      editSidebar: "first",
      typeEdit: "lead",
    };
  },
  methods: {
    async refresh() {
      this.addPreloader();

      const data = await this.$store.dispatch(
        "ParagonClientDashboard/getClientData",
        this.$route.params.idClient
      );
      this.removePreloader();
    },
    async openEditLead() {
      this.addPreloader();
      await this.getLeadEdit();
      if (this.editSidebar === "first") {
        await Promise.all([
          this.getPrograms(),
          this.getStateLeads(),
          this.getStatusLeads(),
          this.getSourceLeads(),
          this.getSourceNames(),
          this.getStates(),
          this.getEeuuStates(),
          this.getCountries(),
          this.getOwners(),
        ]);
        this.editSidebar = "second";
      }
      if (this.S_LEAD_EDIT) {
        this.isAddUpdateUserSidebarActive = true;
      }
      this.removePreloader();
    },
    async getLeadEdit() {
      try {
        await this.A_GET_LEAD_EDIT({ id: this.client.lead_id });
      } catch (error) {
        console.log("Something went wrong getLeadEdit", error);
      }
    },
    async getPrograms() {
      try {
        await this.A_GET_PROGRAMS();
      } catch (error) {
        console.log("Something went wrong getPrograms", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getStateLeads() {
      try {
        await this.A_GET_STATE_LEADS();
      } catch (error) {
        console.log("Something went wrong getStateLeads:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getStatusLeads() {
      try {
        await this.A_GET_STATUS_LEADS();
      } catch (error) {
        console.log("Something went wrong getStatusLeads:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getSourceLeads() {
      try {
        await this.A_GET_SOURCE_LEADS();
      } catch (error) {
        console.log("Something went wrong getSourceLeads:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getSourceNames() {
      try {
        await this.A_GET_SOURCE_NAMES();
      } catch (error) {
        console.log("Something went wrong getSourceNames:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getStates() {
      try {
        await this.A_GET_STATES({ type: 1 });
      } catch (error) {
        console.log("Something went wrong getStates:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getEeuuStates() {
      try {
        await this.A_GET_EEUU_STATES();
      } catch (error) {
        console.log("Something went wrong getEeuuStates:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getCountries() {
      try {
        await this.A_GET_COUNTRIES();
      } catch (error) {
        console.log("Something went wrong getCountries:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getOwners() {
      try {
        const roles = [2, 4].includes(this.moduleId) ? "[1,2,5]" : "[1,2,3,5]";
        await this.A_GET_OWNERS({
          modul: this.moduleId,
          body: { roles, type: "1" },
        });
      } catch (error) {
        console.log("Something went wrong getOwners:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async openModalPaymentsData() {
      await this.getPaymentData(this.accountId);
      this.activeModalPaymentsData = true;
    },
    closeModalPaymentsData() {
      this.activeModalPaymentsData = false;
    },
    async openModalHisitoryMonthlyPayments() {
      await this.getHistoryMonthlyPayments(this.accountId);
      this.activeModalHisitoryMonthlyPayments = true;
    },
    closeModalHisitoryMonthlyPayments() {
      this.activeModalHisitoryMonthlyPayments = false;
    },
    changeStatusMemo(status) {
      if (!status) {
        this.msgMemo = this.memo.msg;
      }
      this.isMemoActive = status;
    },
    async openHistoryStatusModal() {
      await this.getHistoryStatus(this.accountId);
      this.activeModalHistory = true;
    },
    closeModalHistory() {
      this.activeModalHistory = false;
    },
    openModalBenefit() {
      this.activeModalBenefit = true;
    },
    closeModalBenefit() {
      this.activeModalBenefit = false;
    },
    async openModalTrackingChangeAddress() {
      const params = {
        id_module: this.moduleId,
        id_lead: this.leadId,
        typee: 1,
      };
      await this.getTrackingChangeAddress(params);
      this.activeTrackingChangeAddress = true;
    },
    closeModalTrackingChangeAddress() {
      this.activeTrackingChangeAddress = false;
    },
    async editMemo() {
      const params = {
        id: this.client.accountId,
        memo: this.msgMemo,
        user_id: this.currentUser.user_id,
      };
      const confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        const status = await ClientDashboardService.editMemo(params);
        if (status) {
          this.showSuccessSwal();
          const date = Date.now();
          this.SET_MEMO({
            msg: this.msgMemo,
            user: this.currentUser.fullName,
            date,
          });
        } else {
          this.showErrorSwal();
        }

        this.isMemoActive = false;
      }
    },
    setPack(data) {
      this.SET_PACK(data);
      this.openModalBenefit();
    },
    ...mapActions({
      getHistoryStatus: "ParagonClientDashboard/getHistoryStatus",
      getTrackingChangeAddress:
        "ParagonClientDashboard/getTrackingChangeAddress",
      getHistoryMonthlyPayments:
        "ParagonClientDashboard/getHistoryMonthlyPayments",
      getPaymentData: "ParagonClientDashboard/getPaymentData",

      // EditLead
      A_GET_LEAD_EDIT: "CrmLeadStore/A_GET_LEAD_EDIT",
      A_GET_PROGRAMS: "CrmGlobalStore/A_GET_PROGRAMS",
      A_GET_STATE_LEADS: "CrmLeadStore/A_GET_STATE_LEADS",
      A_GET_STATUS_LEADS: "CrmLeadStore/A_GET_STATUS_LEADS",
      A_GET_SOURCE_LEADS: "CrmLeadStore/A_GET_SOURCE_LEADS",
      A_GET_SOURCE_NAMES: "CrmGlobalStore/A_GET_SOURCE_NAMES",
      A_GET_STATES: "CrmGlobalStore/A_GET_STATES",
      A_GET_EEUU_STATES: "CrmGlobalStore/A_GET_EEUU_STATES",
      A_GET_COUNTRIES: "CrmGlobalStore/A_GET_COUNTRIES",
      A_GET_OWNERS: "CrmGlobalStore/A_GET_OWNERS",
    }),
    ...mapMutations({
      SET_MEMO: "ParagonClientDashboard/SET_MEMO",
      SET_PACK: "ParagonClientDashboard/SET_PACK",
    }),
  },
  computed: {
    methodPayment() {
      switch (this.typePayment) {
        case "MA":
          this.msgPopOver = "Manual";
          return "text-primary";
          break;
        case "OT":
          this.msgPopOver = "Others";
          return "text-warning";
          break;
        case "PA":
          this.msgPopOver = "Automatic";
          return "text-success";
          break;
      }
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    address() {
      return `${this.client.address.street || ""} ${
        this.client.address.city || ""
      }, ${this.client.address.states_eeuu_slug || ""}, ${
        this.client.address.zipCode || ""
      }`;
    },
    ...mapState({
      S_LEAD_EDIT: (state) => state.CrmLeadStore.S_LEAD_EDIT,
    }),
    ...mapGetters({
      currentUser: "auth/currentUser",
      memo: "ParagonClientDashboard/getMemo",
      client: "ParagonClientDashboard/getPersonalInputs",
      accountId: "ParagonClientDashboard/getAccountId",
      leadId: "ParagonClientDashboard/getLeadId",
      packs: "ParagonClientDashboard/getPacks",
      skin: "appConfig/skin",
      typePayment: "ParagonClientDashboard/getTypePayment",
    }),
    isResponsibleAccount() {
      if (this.$route.params.idClient) {
        return !(
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === this.currentUser.user_id ||
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === null
        );
      }
      return false;
    },
  },
  created() {
    this.doc = this.client.ssn ? this.client.ssn : this.client.itin || "";
    this.msgMemo = this.memo.msg;
    this.validateImg = true;
    this.validateAccount = true;
    this.service = this.client.services[0]?.name
      ? this.client.services[0]?.name
      : "";
  },
};
</script>
