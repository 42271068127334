<template>
  <b-input-group>
    <b-form-input
      v-if="type == 'text'"
      v-model="info"
      :disabled="!edit"
      :type="type"
      @input="input"
      :max="100"
    />
    <b-form-input
      v-if="type == 'number'"
      v-model="info"
      :disabled="!edit"
      @input="input"
      :max="10"
      v-mask="'##-#######'"
    />
    <b-form-select
      v-if="type == 'select'"
      v-model="info"
      :disabled="!edit"
      :options="dataSelect"
      @change="input"
    ></b-form-select>

    <b-input-group-append>
      <b-button variant="outline-info" v-if="edit" @click="saveChange"
        ><feather-icon icon="SaveIcon"
      /></b-button>
      <b-button :disabled="isResponsibleAccount" variant="outline-warning" v-else @click="editField"
        ><feather-icon icon="Edit3Icon"
      /></b-button>
      <b-button variant="outline-danger" v-if="edit" @click="closeEdit"
        ><feather-icon icon="XIcon"
      /></b-button>
      <b-button
        variant="outline-primary"
        v-else
        :id="`viewButton-${idBtn}`"
        @mouseover="searchTracking"
        @mouseleave="inactivePopOver"
        ><feather-icon icon="EyeIcon"
      /></b-button>
      <b-popover
        :show.sync="popOverIsActive"
        :target="`viewButton-${idBtn}`"
        triggers="hover"
        placement="left"
      >
        <span v-if="trackingCompany === 'empty'"> No records found </span>
        <ul v-else>
          <li
            v-for="(tracking, i) in trackingCompany"
            :key="i"
            class="d-flex flex-column px-1 border-bottom-secondary-1"
          >
            <span>{{ tracking.name_user }}</span
            ><span>{{ tracking.created_at | myGlobalWithHour }}</span>
          </li>
        </ul>
      </b-popover>
    </b-input-group-append>
  </b-input-group>
</template>
<script>
//vuex
import { mapActions, mapGetters } from "vuex";
import ClientDashboardService from "../../../services/dashboard.service";
export default {
  created() {
    this.info = this.model;
    if (this.idBtn === 0) this.randomNumber();
  },
  props: {
    model: { type: [String, Number] },
    type: { type: String, default: () => "text" },
    dataSelect: { type: Array, default: () => [] },
  },
  data() {
    return {
      info: "",
      edit: false,
      idBtn: 0,
      popOverIsActive: false,
    };
  },
  methods: {
    activePopOver() {
      this.popOverIsActive = true;
    },
    inactivePopOver() {
      this.popOverIsActive = false;
    },
    randomNumber() {
      this.idBtn = Math.round(Math.random() * 10000);
    },
    searchTracking() {
      this.activePopOver();
      this.$emit("hover");
    },
    editField() {
      this.edit = true;
    },
    closeEdit() {
      this.info = this.info != this.model ? this.model : this.info;
      this.edit = false;
    },
    saveChange() {
      this.$emit("save", this.info);
      this.edit = false;
    },
    input() {
      this.$emit("input", this.info);
    },
  },
  computed: {
    ...mapGetters({
      trackingCompany: "ParagonClientDashboard/getTrackingCompanyData",
      currentUser: "auth/currentUser",
    }),
    isResponsibleAccount() {
      if (this.$route.params.idClient) {
        return !(this.$store.getters['NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO'].is_responsible === this.currentUser.user_id || this.$store.getters['NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO'].is_responsible === null);
      }
      return false;
    },
  },
};
</script>