<template>
  <div>
    <h2>COMPANY INFORMATION</h2>
    <b-row>
      <b-col>
        <b-form-group label="Company Name">
          <input-edit-view
            :model="company.companyName"
            :type="'text'"
            @save="saveNewCompanyData($event, 1)"
            @hover="searchTracking(1)"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Web Page">
          <input-edit-view
            :model="company.webPage"
            :type="'text'"
            @save="saveNewCompanyData($event, 5)"
            @hover="searchTracking(5)"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="EIN">
          <input-edit-view
            :model="ein"
            :type="'number'"
            @save="saveNewCompanyData($event, 2)"
            @hover="searchTracking(2)"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Social Network">
          <input-edit-view
            :model="company.socialNetwork"
            :type="'text'"
            @save="saveNewCompanyData($event, 6)"
            @hover="searchTracking(6)"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Biz Type">
          <input-edit-view
            v-if="currentUser.modul_id != 3"
            :model="company.bizType1"
            :type="'text'"
            @save="saveNewCompanyData($event, 3)"
            @hover="searchTracking(3)"
          />

          <input-edit-view
            v-else
            :model="company.bizType1"
            :type="'select'"
            :dataSelect="business"
            @save="saveNewCompanyData($event, 3)"
            @hover="searchTracking(3)"
          >
          </input-edit-view>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Toll Free">
          <input-edit-view
            :model="company.tollFree"
            :type="'text'"
            @save="saveNewCompanyData($event, 7)"
            @hover="searchTracking(7)"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Biz Address">
          <input-edit-view
            :model="company.bizType2"
            :type="'text'"
            @save="saveNewCompanyData($event, 4)"
            @hover="searchTracking(4)"
          />
        </b-form-group>
      </b-col>
      <b-col></b-col>
    </b-row>
  </div>
</template>
<script>
//vuex
import { mapActions, mapGetters } from "vuex";
//components
import InputEditView from "../utils/InputEditView.vue";
//service
import ClientDashboardService from "../../../services/dashboard.service";
export default {
  components: {
    InputEditView,
  },
  data() {
    return {
      asd: "dasdasas asdasdasdas dasdasdasd",
      ein: "",
      typee: 0,
      business: [],
    };
  },
  methods: {
    async searchTracking(typee) {
      const params = { id_account: this.accountId, typee };
      if (this.typee === 0 || this.typee != typee) {
        this.typee = typee;
        await this.getTrackingCompanyData(params);
      }
    },
    addHyphen(value) {
      if (value.length === 2) this.ein = `${value}-`;
    },
    async saveNewCompanyData(dataa, typee) {
      const parameters = {
        dataa,
        id_account: this.accountId,
        id_user: this.currentUser.user_id,
        typee,
      };

      const confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        const result = await ClientDashboardService.updateCompanyData(
          parameters
        );

        result
          ? this.showToast("success", "top-right", "Success!", "CheckIcon")
          : this.showToast("danger", "top-right", "Oops!", "XIcon");
      }
    },
    async getBusinessType() {
      this.business = [];
      const response = await ClientDashboardService.getBusinessType();
      this.business = response.data;
      let old = 0;
      this.business.forEach((element) => {
        if (element.text == this.company.bizType1) {
          old = 1;
        }
      });

      if (
        old == 0 &&
        this.company.bizType1 != null &&
        this.company.bizType1 != ""
      ) {
        this.business.push({
          value: this.company.bizType1,
          text: this.company.bizType1,
          disabled: true,
        });
      }
    },
    ...mapActions({
      getTrackingCompanyData: "ParagonClientDashboard/getTrackingCompanyData",
    }),
  },
  computed: {
    ...mapGetters({
      company: "ParagonClientDashboard/getCompanyInputs",
      currentUser: "auth/currentUser",
      accountId: "ParagonClientDashboard/getAccountId",
    }),
  },
  created() {
    this.ein = this.company.ein;
    this.getBusinessType();
  },
};
</script>